import React from 'react';
import PAGE_CONTETNTS from '../../data';
import ReactHtmlParser from 'react-html-parser';
import Footer from '../footer/footer';
import './about-us-detail.styles.scss';

const AboutUsDetail = () => {
    const { details } = PAGE_CONTETNTS.about;
    return (
        <div className="about-us-detail">
            <section className="about-us rn-about-top container-mt">
                <div className="wrapper-md">
                    <div className="rn-about-container has-margin">
                        <div className="col-two rn-about-text">
                        {ReactHtmlParser(details.title)}
                            <p className="p-lg">{ details.intro } </p>
                            <p className="p-sm">{ details.quote }</p>
                            <div className="author">{details.author}</div>
                        </div>
                        <div className="col-two rn-about-image bg-image" style={{ backgroundImage: `url(${details.image})`}}></div>
                    </div>
                </div>
            </section>
            <section className="about-us rn-about-bottom">
                <div className="wrapper-md">
                    <h4>{details.footerTitle}</h4>
                    <div className="rn-about-inner">
                        <div className="rn-about-container has-margin">
                            <div className="col-three au-first">
                                <p className="p-sm">{ details.footerPara1 }</p>
                                <p className="p-sm">{ details.footerPara2 }</p>
                            </div>
                            <div className="col-three au-second">
                                <p className="p-sm">{details.footerPara3}</p>
                                <p className="p-sm">{ details.footerPara4 }</p>
                                <p className="p-sm">The reach nepal team</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer/>
        </div>
    );
}

export default AboutUsDetail;