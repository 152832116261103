import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import PAGE_CONTENT from '../../data';
import ReactHtmlParser from 'react-html-parser';
import ToastMessage from '../toast-message/toast-message';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { config } from "../../config"
import Fade from 'react-reveal/Fade';
// import Slide from 'react-reveal/Slide';



const ContactUs = ({ history }) => {
  const [toast, setToast] = useState({
    show: false,
    toastMessage: '',
    color: ''
  })

  const { address, tel, email } = PAGE_CONTENT.contact;
  const { show, toastMessage, color } = toast;

  //display toast message
  const showToast = (toastMessage, color) => {
    setToast({ show: true, toastMessage: toastMessage, color: color })
    setTimeout(() => setToast({ show: false, toastMessage: ' ', color: '' }), 5000)
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const firstName = data.get('firstName');
    const emailAddress = data.get('emailAddress');
    const phone = data.get('phone');
    const message = data.get('message');
    if (!firstName || !emailAddress || !phone || !message) {
      return showToast('All fields are required.', '#de350b')
    }
    fetch(`${config.baseUrl}/formsubmit`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        firstName,
        emailAddress,
        phone,
        message
      })
    })
      .then(resp => resp.json())
      .then(data => {
        if (data === 'email sent successfully') {
          showToast('Submission Successful', '#ddffcc');
          return setTimeout(() => history.push('/'), 5000)
        } else {
          return showToast('Submission Unsuccessful, all fields are requied', '#de350b');
        }
      })
      .catch(error => console.log(error))
  }

  return (
    <div className="scroll-slides get-in-touch d-flex">
      <div className="hero-text hero-rear">
        <Fade right duration={1500}>
          <h1 className="hero-title title-rear">
            Get in Touch
          </h1>
        </Fade>
      </div>
      <div className="hero-section">
        <div className="hero-text hero-face">
          <Fade right duration={1500} >
            <h1 className="hero-title title-face">
              Get in Touch
            </h1>
          </Fade>
        </div>
        <div className="hero-container container-mt">
          <Fade cascade text delay={700} duration={1500}>
            <div className="col-two git-contact">
              <div className="git-box git-address">
                <span className="git-title">Address</span>
                <p className="p-xl">
                  {ReactHtmlParser(address)}
                </p>
              </div>
              <div className="git-box git-phone">
                <span className="git-title">Phone</span>
                <p className="p-xl">{tel}</p>
              </div>
              <div className="git-box git-email">
                <span className="git-title">Email</span>
                <p className="p-xl">{email}</p>
              </div>
            </div>
          </Fade>
          <Fade cascade text delay={700} duration={1500}>
            <div className="col-two git-form">
              <ToastMessage show={show} toastMessage={toastMessage} color={color} />
              <h4>Leave us a few lines</h4>
              <form onSubmit={(event) => handleSubmit(event)} method="POST">
                <div className="form-element form-element-name">
                  <label>First Name <span></span></label>
                  <input type="text"
                    name="firstName"
                    placeholder="John Doe"
                    required
                  />
                </div>
                <div className="form-element form-element-email">
                  <label>Email <span></span></label>
                  <input type="text"
                    name="emailAddress"
                    placeholder="mail@johndoe.com"
                    pattern="[^ @]*@[^ @]*"
                    required
                  />
                </div>
                <div className="form-element form-element-phone">
                  <label>Phone <span></span></label>
                  <PhoneInput
                    country={'us'}
                    inputProps={{
                      name: 'phone',
                      required: true,
                      autoFocus: true
                    }}
                  />
                </div>
                <div className="form-element form-element-message">
                  <label>Message <span></span></label>
                  <textarea name="message"
                    placeholder="Type Your message here">
                  </textarea>
                </div>
                <div className="form-element form-element-submit">
                  <input type="submit" value="Submit" placeholder="Submit" />
                </div>
              </form>
            </div>
          </Fade>
        </div>
      </div>

      <div className="hero-counter">
        04<span>/04</span>
      </div>
    </div>
  );
}

export default withRouter(ContactUs);
