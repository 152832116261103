import React from 'react';
import './toast-message.scss';

const ToastMessage = ({ show, toastMessage, color }) => {
    const toastStyle = {
        width: '100% !important',
        border: '1px solid #ddd',
        background: `${color}`,
        padding: '3%',
        margin: '14px'
    }
    return show && (
        <div className="toast-message" style={ toastStyle }>
            <p>{toastMessage}</p>
        </div>
    );
}

export default ToastMessage;