import React from 'react';
import { Link } from 'react-router-dom';
import PAGE_CONTENT from '../../data';
import ReactHtmlParser from 'react-html-parser';
import Fade from 'react-reveal/Fade';


const FeaturedProjects = () => {
    const { title, image, position } = PAGE_CONTENT.featuredProjects;
    const  jeewitBachanImage  = image[0];
    const  healthEducationImage = image[1];
    const  publishingMinistryImage = image[2];
    console.log(publishingMinistryImage);
    return(
      <div className="featured-projects">
      <Fade right>
        <div className="scroll-slides features-projects d-flex">
                <div className="hero-text hero-rear">
                    <h1 className="hero-title title-rear">
                       { ReactHtmlParser(title) }
                    </h1>
                </div>
                <div className="hero-section">
                    <div className="hero-text hero-face">
                        <h1 className="hero-title title-face">
                        { ReactHtmlParser(title) }
                        </h1>
                    </div>
                    <div className="hero-container container-mt">
                        <div className="fp-item fp-jeewit bg-image" style={{ backgroundImage: `url(${jeewitBachanImage})`}}>
                                <div className="fp-content">
                                    <h4>Jeewit Bachan <br/>Online</h4>
                                    <div className="btn-container">
                                        <Link to="#" className="btn btn-more">Learn More</Link>
                                    </div>
                                </div>
                        </div>
                        <div className="fp-item fp-health bg-image" style={{ backgroundImage: `url(${healthEducationImage})`}}>
                            <div className="fp-content">
                                <h4>Health <br/>Education</h4>
                                <div className="btn-container">
                                    <Link to="#" className="btn btn-more">Learn More</Link>
                                </div>
                            </div>
                        </div>
                        <div className="fp-item fp-publishing bg-image" style={{ backgroundImage: `url(${publishingMinistryImage})`}}>
                            <div className="fp-content">
                                <h4>Publishing <br/>Ministry</h4>
                                <div className="btn-container">
                                    <Link to="#" className="btn btn-more">Learn More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="arrow-nav">
                        <Link to="/connect"></Link>
                    </div>
                    
                </div>
                <div className="hero-counter">
                    0{position}<span>/04</span>
                </div>
            </div>
            </Fade>
        </div>
    );
}

export default FeaturedProjects;