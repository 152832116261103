import React from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
// import { TransitionGroup, CSSTransition } from "react-transition-group";
import Header from './components/header/header';
import HomePage from './pages/homepage/homepage';
import AboutPage from './pages/about/about';
import FeaturedProjectsPage from './pages/featured-projects/featured-projects';
import Contact from './pages/contact/contact';
import DiscoverPage from './pages/discover/discover';
import ProjectsPage from './pages/projects/projects';
import GivePage from './pages/give/give-page';
import AboutUsDetail from './components/about-us/about-us-detail';
import ProjectDetails from './components/projects/project-details';
import './app.scss';


function App() {

  const noMatchFoundStyles = {
    marginTop: '300px',
    textAlign: 'center'
  }

   //this component will render for invalid path.
   const NoMatchRoute = () => {
    const history = useHistory();
    return(
      <div className="wrapper">
      {
        setTimeout(() => {
          history.push('/');
        }, 5000)
      }
        <div style={noMatchFoundStyles}>
          <h3 className="alarmmsg">Oops, The page doesn't exist. Redirecting to homepage....</h3>
        </div>
      </div>
    );
  }


  return (
    <>
      <Header/>
        <Switch>
          <Route exact path="/" component={HomePage}/>
          <Route exact path="/about" component={AboutPage}/>
          <Route exact path="/about-detail" component={AboutUsDetail}/>
          <Route exact path="/featured-projects" component={FeaturedProjectsPage}/>
          <Route exact path="/connect" component={Contact}/>
          <Route exact path="/discover" component={DiscoverPage}/>
          <Route exact path="/projects" component={ProjectsPage}/>
          <Route exact path="/(give|support)" component={GivePage}/>
          <Route path="/projects/:projectId" component={ProjectDetails}/>
          <Route component={NoMatchRoute}/>
        </Switch>
    </>
  );
}

export default App;
