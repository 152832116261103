import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";

import AboutUs from '../../components/about-us/about-us';
import {routes, debounce } from '../../scroll';


const AboutPage = () => {

    const history = useHistory();
    var bufferFn = debounce(changeSlide,400);

    useEffect(()=>{
        window.addEventListener('wheel',bufferFn);
        document.body.classList.add('mac-scroll');
        return () => {
            window.removeEventListener('wheel', bufferFn);
            document.body.classList.remove('mac-scroll');
        };
      },[]);

      function changeSlide(e) {
        let delta = e.deltaY;
        if (delta >= 0) {
            //next
            history.replace(routes[2].route);
        } else if (delta < 0) {
            //prev            
            history.replace(routes[0].route);
        }
    }

    return(
        <div className="about-page">
            <div className="vh-section">
                <div className="scroll-container">
                    <div className="scroll-wrapper">
                        {/* <Fade right duration={700}> */}
                            <AboutUs/>
                        {/* </Fade> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutPage;