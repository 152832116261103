
import PAGE_CONTENTS from '../../data';

//display toast message
export const showToast = (toastMessage) => {
    this.setState({ toast: true, toastMessage: toastMessage })
    setTimeout(() => this.setState({ toast: false, toastMessage: ' ' }), 5000)
}

//convert Object into array
export const convertObjectToArray = (currentObject) =>  {
    return Object.keys(currentObject).map(key => currentObject[key]);
}

//get project details from the data.js file that matches the params
export const getProjectDetails = (projectTitle) => {
    const { projectsDetails } = PAGE_CONTENTS.projects;
    return projectsDetails[projectTitle];

}

//calculate raised amount in percentage

export const calculateRaisedPercentage = (raised, total) => {
    const percentage = (raised / total) * 100;
    return Math.floor(percentage);
}
