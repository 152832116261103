
import React, {useEffect} from 'react';
import { useHistory } from "react-router-dom";

import {routes, debounce } from '../../scroll';
import Home from '../../components/home/home';

// import Fade from 'react-reveal/Fade';

const HomePage = () => {
    const history = useHistory();
    var bufferFn = debounce(changeSlide,400);

 
    useEffect(()=>{
        window.addEventListener('wheel', bufferFn);
        document.body.classList.add('mac-scroll');
        return () => {
            window.removeEventListener('wheel', bufferFn);
            document.body.classList.remove('mac-scroll');

        };
      },[]);

    function changeSlide(e) {
        let delta = e.deltaY;
        if (delta >= 0) {
            //next
            history.replace(routes[1].route);
        } else if (delta < 0) {
            //prev            
            // history.replace(routes[3].route);
        }
    }

    return(
        <div className="homepage">
            <div className="vh-section">
                <div className="scroll-container">
                    <div className="scroll-wrapper">
                        {/* <Fade right duration={20000}  opposite exit when={ex} > */}
                            <Home/>
                        {/* </Fade> */}
                    </div>
                </div>
            </div>
        </div>
    );
}


export default HomePage;