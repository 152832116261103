import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/images/brand-logo.png';
import './header.styles.scss';

const Header = () => {
    const [ isOpen, setIsOpen] = useState(false);

    //this function will toggle open class
    const toggleOpen = () => {
        //get site navigation class
        const getSiteNavBlock = document.querySelector('.site-navigation');
        const getSiteMobieNav = document.querySelector('.site-mobile-nav');

        if(getSiteNavBlock.classList.contains('open')) {
            setIsOpen(prevState => ({
                ...prevState,
                isOpen: false
            }));
            getSiteNavBlock.classList.remove('open');
            getSiteMobieNav.classList.remove('open');
            
        }else{
            setIsOpen(prevState => ({
                ...prevState,
                isOpen: true
            }));
            getSiteNavBlock.classList.add('open');
            getSiteMobieNav.classList.add('open');
        }
    }

    //Hides the nav if itOpen state is true
    const hideNav = () => {
        //get site navigation class
        const getSiteNavBlock = document.querySelector('.site-navigation');
        const getSiteMobieNav = document.querySelector('.site-mobile-nav');

        if(isOpen) {
            getSiteNavBlock.classList.remove('open');
            getSiteMobieNav.classList.remove('open');
        }
    }

    return(
        <div id="header" className="site-header">
    <div className="wrapper">
        <div className="header-inner">
            <div id="site-brand" className="site-logo">
                <Link to="/" style={{ display: 'block' }}>
                    <img src={logo} alt="logo"/>
                </Link>
            </div>
            <div id="site-nav" className="site-navigation d-flex">
                <ul className="nav-container d-inline-flex">
                    <li className="nav-item">
                        <Link to="/" onClick={hideNav}>Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/about" onClick={hideNav}>About</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/projects" onClick={hideNav}>Projects</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/connect" onClick={hideNav}>Contact</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/discover" onClick={hideNav}>Discover Nepal</Link>
                    </li>
                    <li className="nav-item nav-give">
                        <Link to="/give" onClick={hideNav}>Give</Link>
                    </li>
                </ul>
            </div>
            <div className="site-mobile-nav" 
                style={{ cursor: 'pointer'}} 
                onClick={toggleOpen}>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</div>
    );
}

export default Header;