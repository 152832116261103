import React from 'react';
import { Link } from 'react-router-dom';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';
import Flash from 'react-reveal/Flash';

import PAGE_CONTENT from '../../data';
import ReactHtmlParser from 'react-html-parser';

const Home = () => {
    const { title, content, image, author, position } = PAGE_CONTENT.home;
    return (
        <div className="scroll-slides everlasting d-flex">
            <div className="hero-text hero-rear">
                <Fade right delay={700} duration={1500}>
                    <h1 className="hero-title title-rear">
                        {ReactHtmlParser(title)}
                    </h1>
                </Fade>
            </div>
            <Slide right  delay={100} duration={1300}>
                <div className="hero-section bg-image" style={{ backgroundImage: `url(${image})` }}>
                    <div className="hero-text hero-face">
                        <Fade right delay={700} duration={1500}>
                            <h1 className="hero-title title-face">
                                {ReactHtmlParser(title)}
                            </h1>
                        </Fade>
                        <Fade cascade delay={2000} duration={1500}>
                            <div className="hero-content">
                                <p>{content}</p>
                                <span className="author">{author}</span>
                                <div className="btn-container">
                                    <Link to="/projects" className="btn btn-more">Explore Projects</Link>
                                </div>
                            </div>
                        </Fade>
                    </div>
                    <Flash delay={2500} duration={1000}>
                        <div className="arrow-nav">
                            <Link to="/about"></Link>
                        </div>
                    </Flash>
                </div>
            </Slide>
            <div className="hero-counter">
                0{position}<span>/04</span>
            </div>
        </div>
    );
}

export default Home;