import React from 'react';
import { Link } from 'react-router-dom';
import footerLogo from '../assets/images/brand-logo.png';
import './footer.scss';

const Footer = () => {
    return(
        <div id="footer" className="site-footer">
            <div className="wrapper">
                <div className="footer-inner">
                    <div id="footer-brand" className="footer-logo">
                        <Link to="/">
                            <img src={footerLogo} alt="footer-logo"/>
                        </Link>
                    </div>
                    <div className="footer-info">
                        &copy; {(new Date().getFullYear())} Reach Nepal, Inc <span>|</span> Built by <a href="https://webpoint.io" target="_blank" rel="noopener noreferrer" >Webpoint Solutions, LLC</a>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default Footer;