import React from 'react';
import PAGE_CONTENTS from '../../data';
import ReactHtmlParser from 'react-html-parser';

import Fade from 'react-reveal/Fade';

import './discover.styles.scss';


const Discover = () => {
    const { discover } = PAGE_CONTENTS;
    const { landscape, culture, language, religion, hinduism, buddhism, islam, christianity } = discover;
    return(
        <div className="">
            <div className="discover">
               <Fade>
                <section className="discover-nepal dn-section container-mt">
                        <div className="wrapper-sm">
                            <div className="dn-container">
                                <h2>Discover Nepal</h2>
                                {ReactHtmlParser(discover.intro)}
                            </div>
                        </div>
                    </section>
               </Fade>

                {/* Text Image Section */}
                <section className="dn-text-image div-text-image no-padding">
                    
                        <div className="dti-items dn-landscape">
                            <Fade left>
                                <div className="col-two dti-image bg-image" style={{ backgroundImage: `url(${landscape.image})`}}></div>
                            </Fade>
                            <Fade right>
                                <div className="col-two dti-text">
                                    <h6>{landscape.heading}</h6>
                                    { ReactHtmlParser(landscape.content)}
                                </div>
                            </Fade>
                        </div>
                   
                        <div className="dti-items dn-culture">
                          <Fade right>
                            <div className="col-two dti-image bg-image" style={{ backgroundImage: `url(${culture.image})`}}>
                                </div>
                          </Fade>
                          <Fade left>
                            <div className="col-two dti-text">
                                <h6>{culture.heading}</h6>
                                    { ReactHtmlParser(culture.content)}
                                </div>
                          </Fade>
                        </div>
                   
                        <div className="dti-items dn-language">
                            <Fade left>
                                <div className="col-two dti-image bg-image" style={{ backgroundImage: `url(${language.image})`}}>
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="col-two dti-text">
                                <h6>{language.heading}</h6>
                                    { ReactHtmlParser(language.content)}
                                </div>
                            </Fade>
                        </div>
                    
                </section>

                {/* Religion Container */}
                <Fade>
                    <section id="religion" className="religion-nepal dn-section">
                        <div className="wrapper-sm">
                            <div className="dn-container">
                                <h2>{religion.heading}</h2>
                            {ReactHtmlParser(religion.content)}
                            </div>
                        </div>
                    </section>
                </Fade>
                {/* Text Image Section */}
                <section className="rg-text-image div-text-image no-padding">
                   
                        <div className="dti-items dn-hinduism">
                            <Fade left>
                                <div className="col-two dti-image bg-image" style={{ backgroundImage: `url(${hinduism.image})`}}>
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="col-two dti-text">
                                <h6>{hinduism.heading}</h6>
                                    { ReactHtmlParser(hinduism.content)}
                                </div>
                            </Fade>
                        </div>
                    
                   
                        <div className="dti-items dn-buddhism">
                            <Fade right>
                                <div className="col-two dti-image bg-image" style={{ backgroundImage: `url(${buddhism.image})`}}>
                                </div>
                            </Fade>
                            <Fade left>
                                <div className="col-two dti-text">
                                <h6>{buddhism.heading}</h6>
                                    { ReactHtmlParser(buddhism.content)}
                                </div>
                            </Fade>
                        </div>
                  
                        <div className="dti-items dn-islam">
                            <Fade left>
                                <div className="col-two dti-image bg-image" style={{ backgroundImage: `url(${islam.image})`}}>
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="col-two dti-text">
                                <h6>{islam.heading}</h6>
                                    { ReactHtmlParser(islam.content)}
                                </div>
                            </Fade>
                        </div>
                  
                        <div className="dti-items dn-christianity" >
                            <Fade right>
                                <div className="col-two dti-image bg-image" style={{ backgroundImage: `url(${christianity.image})`}}>
                                </div>
                            </Fade>
                            <Fade left>
                                <div className="col-two dti-text">
                                <h6>{christianity.heading}</h6>
                                    { ReactHtmlParser(christianity.content)}
                                </div>
                            </Fade>
                        </div>
                </section>
            </div>
        </div>
    );
}

export default Discover;