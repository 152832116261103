import homeBannerImage from './components/assets/images/img-home.jpg';
import  jeewitBachanImage from './components/assets/images/img-jeewit-bachan.jpg';
import  healthEducationImage from './components/assets/images/img-health-education.jpg';
import  publishingMinistryImage from './components/assets/images/img-publishing-ministry.jpg';
import landscapeImage from './components/assets/images/landscape.jpg';
import cultureImage from './components/assets/images/culture.jpg';
import languageImage from './components/assets/images/language.jpg';
import hinduismImage from './components/assets/images/hinduism.jpg';
import buddhismImage from './components/assets/images/buddhism.jpg';
import islamImage from './components/assets/images/islam.jpg';
import christianityImage from './components/assets/images/christianity.jpg';
import jeewitBachanOnlineImage from './components/assets/images/img-jeewit-bachan.jpg';
// import buildingProjectImage from './components/assets/images/img-building-project.jpg';
import publishingImage from './components/assets/images/img-publishing-ministry.jpg';
import translationImage from './components/assets/images/img-translation.jpg';
import healthOutreachImage from './components/assets/images/img-health-outreach.jpg';
 
const PAGE_CONTENTS = {
    home: {
        title: `The Everlasting Gospel to every Nepali person around the globe`,
        content: 'And This Gospel of The Kingdom Shall Be Preached In All The World As A Witness To All The Nations, And Then The End Will Come.',
        author: 'Matthew 24:14',
        image: homeBannerImage,
        position: 1
    },
    about: {
        title: 'About Us',
        content: `Since 2013, Reach Nepal has been actively engaged in resource development and outreach programs throughout Nepal in response to the commission given by Christ.`,
        image: homeBannerImage,
        position: 2,
        details: {
            title: `<h2>Transforming Lives<br/> Through A Wholistic<br/> Outreach Approach</h2>`,
            intro: "We strive to follow christ's method in soul-winning by following a wholistic outreach approach. A combined ministry of personal gospel outreach, media ministry, and medical missionary work will open many homes to receive the gospel message",
            quote: "Christ's method alone will give true success in reaching the people. The saviour mingled with men as one who desired their good. He showed his sympathy for them, ministered to their needs, and won their confidence. Then he bade them, 'Follow Me'.",
            author: "~ The Ministry Of Healing 143",
            image: homeBannerImage,
            footerTitle: 'We are a supporting ministry of the seventh-day adventist church dedicated to uphold and spread the vital truths that God has committed to us through the Bible and the writings of Ellen White.',
            footerPara1: 'We search for, train, and support gospel workers who have demonstrated a deep commitment to jesus christ and his truth.',
            footerPara2: 'We develop media resources such as websites, tv programs, and audiobooks because we believe that in this age of technology we must use these avenues to more effectively reach the people.',
            footerPara3: 'We network with conference and division refugees ministries to provide resources for the Nepali-speaking Bhutanese refugees.',
            footerPara4: 'We invite you join us through your prayers and support of this ministry'
        }
    },
    featuredProjects: {
        title:`Features<br/> Projects`,
        image: [
            jeewitBachanImage,
            healthEducationImage,
            publishingMinistryImage
        ],
        position: 3
    },
    contact: {
        address: ` Reach Nepal<br/>
        PO Box 704<br/>
        McCaysville, GA 30555`,
        tel: '+1 (470) 755-6225',
        email: 'connect@reachnepal.org'
    },
    discover: {
        intro: `<p>
        Nepal is a facinating country located between Tibet, China to the north and India to the south. Nepal has a population of around 30 million, and is home to a rich diversity of language, culture, and religion.
        
    </p>`,
        landscape: {
            heading: 'Landscape',
            content: `<p>Nepal is probably best known for Mount Everest, the tallest mountain peak in the word. And throughout Nepal, the landscape is marked by the mountain beauty of the snow-capped Himalyan range, lush green foothills, raging rivers, and furtile plains along it's border with India.</p>`,
            image: landscapeImage
        },

        culture: {
            heading: 'Culture',
            content: `<p>Nepal has a rich and varied cultur with many customs and traditions differering from one part of Nepal to another. A prominent factor in a Nepali’s everyday life is religion. Adding color to the lives of Nepalis are festivals throughout the year which they celebrate with colorful displays, parades and various celebrations. Food plays an important role in the celebration of these festivals</p>`,
            image: cultureImage
        },

        language: {
            heading: 'Language',
            content: `<p>Nepali is the official and most widely speken language in Nepal, however there are around 123 languages spoken as mother tongues within various ethnic groups. The Nepal language uses the same script as Hindi and shares words.</p>`,
            image: languageImage
        },

        religion: {
            heading: 'Religion',
            content: `<p>Nepal was declared a secular country by the Parliament on May 18, 2006, and freedom of religion is also guaranteed by the Nepali constitution, but conversion to other religions from Hinduism is prohibited by law. Religions practiced in Nepal are: Hinduism, Buddhism, Islam, Christianity, Jainism, Sikhism, Bon, ancestor worship and animism. The majority of Nepalis are  Hindu with a large number of Buddhists as well. While only a small percentage are Christian, the church has been rapidly spready in recent years. </p>`
        },

        hinduism: {
            heading: 'Hinduism',
            content: `<p>Hindu Nepalis worship the ancient Vedic gods. Bramha the Creator, Vishnu the Preserver and Shiva the Destroyer, are worshipped as the Supreme Hindu Trinity. People pray to the Shiva Linga or the phallic symbol of Lord Shiva in most Shiva temples. Shakti, the dynamic element in the female counterpart of Shiva, is also highly revered and feared.</p>`,
            image: hinduismImage
        },

        buddhism: {
            heading: 'Buddhism',
            content: `<p>Buddha is widely worshipped by both Buddhists and Hindus of Nepal. The five Dhyani Buddhas; Vairochana, Akshobhaya, Rathasambhava, Amitabha and Amoghasiddhi, represent the five basic elements: earth, fire, water, air and ether. Buddhist philosophy conceives these deities to be the manifestations of Sunya or absolute void. Mahakaala and Bajrayogini are Vajrayana Buddhist deities worshipped by Hindus as well. Most buddhist live in the Himalayan region closer to the border of Tibet.</p>`,
            image: buddhismImage
        },

        islam: {
            heading: 'Islam',
            content: `<p>Islam has been a major religion in Nepal for many years. It was believed to be brought here by Bengladeshi, Pakistani, and Indian muslims. There are currently a little over 1 million muslims living in Nepal accounting for some 4% of the population. They are primarily located along Nepal's souther border with India.</p>`,
            image: islamImage
        },

        christianity: {
            heading: 'Christianity',
            content: `<p>Since the end of the monarchy in Nepal, the Christian church has been spreading rapidy throughout the country. Many denominations are present in Nepal, but the pentecostal churches have been foremost in spreading and evangelising throughout the country.</p>`,
            image: christianityImage
        }
    },
    projects: {
        jeewitBachanOnline: {
            title: 'Jeewit Bachan Online',
            linkUrl: '/projects/1',
            image: jeewitBachanOnlineImage,
        },
        // buildingProject: {
        //     title: 'Outpost Center Construction',
        //     linkUrl: '/projects/2',
        //     image: buildingProjectImage,
            
        // },
        publishing: {
            title: 'Literature Publishing',
            linkUrl: '/projects/3',
            image: publishingImage,
        },
        translation: {
            title: 'Book Translation',
            linkUrl: '/projects/4',
            image: translationImage,
        },
        healthOutreach: {
            title: 'Community Health Outreach',
            linkUrl: '/projects/5',
            image: healthOutreachImage,
        },
        projectsDetails: {
           1: {
            title: 'Jeewit Bachan Online',
            raised: 300,
            budget: 750,
            giveUrl: 'https://donorbox.org/jeewit-bachan-online-rn?default_interval=o&hide_donation_meter=true',
            intro: `Now more than ever before, digital media has become an increasingly effective means of communicating the everlasting gospel. With an ever increasing number of people online through smartphones and social media, we have an opportunity to reach people who would otherwise not be reached. Today, literally millions of Nepalese are on Facebook and YouTube and many are living in countries where they cannot access religious materials in their own language. As a result, we have decided to focus our efforts in this area. Through our website <a href="https://jeewitbachan.com">JeewitBachan.com</a>, we have been sharing many important resources. Now we are working on our new mobile app which will have additional resources such as Bible studies, the adult Sabbath School lessons text and video, answers to common Bible questions in text and video, as well as our EGW books, audiobooks, articles and videos. Additionally, we have been sharing a lot of content through our Jeewit Bachan Facebook page and YouTube channel including live-streamed church services.`,
            desc: "NOTE: Many of you know we ran a television program for a number of years on local and national television stations. After much prayer and consideration, it was determined that our money would be more effectually used by supporting the online ministry. Our last program was broadcast in August 2019. We know that many people were blessed by the programs and we may consider starting a television program again in the future if God indicates.",
            desc2: " ",
            image: jeewitBachanImage,
            isMonthly: true,
            videosId: [
                'MEclTzznyfM',
                'hQlOaALLpgw',
                'hr2_03C-7nI',
                '0tyRis6Md6A',
                'p1FlDWLFgIM',
                'S4ablGHf3wM'

            ]
           },

        //    2: {
        //     title: 'Outpost Center Construction',
        //     raised: 34000,
        //     budget: 70000,
        //     giveUrl: 'https://donorbox.org/building-project-rn?default_interval=o&hide_donation_meter=true',
        //     intro: "As earth’s final crisis rapidly approaches, we have felt more and more urgency to locate our team and families in a rural location. Here we can grow our own food & hold training and outreach programs, and build our studio. We have already purchased a plot of land that is in an optimal climate for living. It has rich soil for cultivation and an abundant supply of clean water available throughout the year. Many people in the community are Christian, and we have been supporting a local church with our messages.",
        //     desc: "As of December, 2019, we have begun leveling the land in preparation for construction. Now we are in the process of finalizing the construction materials, drawing up the blueprints, and selecting the contractors who will do the construction",
        //     desc2: " ",
        //     image: buildingProjectImage,
        //     isMonthly: false,
        //    },
           3: {
            title: 'Literature Publishing',
            raised: 300,
            budget: 500,
            giveUrl: 'https://donorbox.org/publishing-ministry-rn?default_interval=o&hide_donation_meter=true',
            intro: "The printed page goes where the living messenger can never reach, and we have been supporting the publishing work in Nepal. Though the literacy rate in Nepal has been relatively low compared to most other countries in Asia, in recent years many more people have been getting an education, and literacy rates are on the rise. Printed materials remain a very effective way of sharing with others, and yet there is a serious lack of good resources. Because of this, Reach Nepal has been committed to the development and printing of various materials such as books, tracts, and magazines.",
            desc: "We are currently working on several important projects including True Revival, Thoughts from the Mount of Blessing, tracts of various topics, a book of common Bible questions and answers, and soon various health materials.",
            desc2: " ",
            image: publishingImage,
            isMonthly: true,
           },
           4: {
            title: 'Book Translation',
            raised: 500,
            budget: 500,
            giveUrl: 'https://donorbox.org/publishing-ministry-rn?default_interval=o&hide_donation_meter=true',
            intro: "The printed page goes where the living messenger can never reach, and we have been supporting the publishing work in Nepal. Though the literacy rate in Nepal has been relatively low compared to most other countries in Asia, in recent years many more people have been getting an education, and literacy rates are on the rise. Printed materials remain a very effective way of sharing with others, and yet there is a serious lack of good resources. Because of this, Reach Nepal has been committed to the development and printing of various materials such as books, tracts, and magazines.",
            desc: "We are currently working on several important projects including True Revival, Thoughts from the Mount of Blessing, tracts of various topics, a book of common Bible questions and answers, and soon various health materials.",
            desc2: " ",
            image: translationImage,
            isMonthly: true,
           },
           5: {
            title: 'Community Health Outreach',
            raised: 250,
            budget: 1500,
            giveUrl: 'https://donorbox.org/health-outreach-rn?default_interval=o&hide_donation_meter=true',
            intro: "We believe that the health of the body directly affects the mind, and in turn affects a person's spirituality. We aim to follow Christ's method in reaching people through comprehensive community health education and resource development. ",
            desc: `In the spring of 2020 we developed and launched our new health focused website, <a href="https://nirogijeewan.com">NirogiJeewan.com</a> which is a part of our new branch of ministry called Nirogi Jeewan. In the Nepali language this basically means a Disease-Free Life. We will endeavor to lift up our communities through print materials such as tracts and health magazines, online resources though <a href="https://nirogijeewan.com">NirogiJeewan.com</a>, and community health education programs including lifestyle counseling, health expos, and medical camps.`,
            desc2: " ",
            image: healthOutreachImage,
            isMonthly: true,
           }
        }
    }

}

export default PAGE_CONTENTS;