import React, { useState, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';
import { getProjectDetails, calculateRaisedPercentage } from "../utils/utils";
import "./project-details.styles.scss";

const ProjectDetails = ({ match, history }) => {
  const [project, setProject] = useState(null);
  // const [thermometer, setThermometer] = useState(0);
  const projectTitle = match.params.projectId;

  useEffect(() => {
    const project = getProjectDetails(projectTitle);

        if(project !== undefined) {
            // const thermometer = calculateRaisedPercentage(
            //     project.raised,
            //     project.budget 
            //   );
            setProject(project);
            // setThermometer(thermometer);
        } else return history.push('/');
  }, []);

  return (
    project && (
      <div className="project-details">
        <section
          id="single-project"
          className="single-project sp-top container-mt"
        >
          <div className="wrapper-md">
            <div className="sp-container has-margin">
              <div className="col-two sp-text">
                <h4>{project.title}</h4>
                {/* <div className="amount-loader">
                  <div className="loader-container">
                    <span
                      className="loader"
                      style={{ width: `${thermometer}%` }}
                    ></span>
                    <span className="loader-text-inner">{thermometer}%</span>
                  </div>
                  <div className="loader-text">
                   { project.isMonthly ? ` Monthly ${project.raised} of ${project.budget}` : `${project.raised} of ${project.budget}` }
                  </div>
                </div> */}
                <div className="sp-text">
                  <p className="p-sm">{ReactHtmlParser(project.intro)}</p>
                  <p className="p-sm">{ReactHtmlParser(project.desc)}</p>
                  <p className="p-sm">{ReactHtmlParser(project.desc2)}</p>
                </div>
                <div className="btn-container">
                  <a className="custom-dbox-popup btn btn-more btn-give" href={project.giveUrl}>Give to this project</a>
                </div>
              </div>
              <div
                className="col-two sp-image bg-image"
                style={{ backgroundImage: `url(${project.image})` }}
              ></div>
            </div>
          </div>
        </section>

        {/* videos display section */}
       { project.videosId && <section id="video-library" className="single-project video-project">
          <div className="wrapper-md">
            <h4>Video Library</h4>
            <div className="sp-container vp-container has-margin">
                { 
                  project.videosId.map((video, idx) => {
                    return <div key={idx} className="col-three vp-video">
                        <div className="iframe-wrapper">
                            <iframe
                                title={`${video}`}
                                width="560"
                                height="315"
                                src={`https://www.youtube.com/embed/${video}`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            ></iframe>
                        </div>
                    </div>
                  })
                }
            </div>
            <div className="btn-container">
              <a href="https://www.youtube.com/channel/UCaZrvuy_4QsClMYRCcbyMjQ" className="btn btn-more">JEEWIT BACHAN YOUTUBE CHANNEL</a>
            </div>
          </div>
        </section> 
        }
      </div>
    )
  );
};

export default ProjectDetails;
