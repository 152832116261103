import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PAGE_CONTENT from '../../data';
import ToastMessage from '../toast-message/toast-message';
import ReactHtmlParser from 'react-html-parser';

import Slide from 'react-reveal/Slide';
import Flash from 'react-reveal/Flash';
import Fade from 'react-reveal/Fade';

import '../assets/styles/styles.scss';
import { config } from "../../config"

const AboutUs = ({ history }) => {

  const [toast, setToast] = useState({
    show: false,
    toastMessage: '',
    color: ''
  })

  const { title, content, image, position } = PAGE_CONTENT.about;
  const { show, toastMessage, color } = toast;

  //display toast message
  const showToast = (toastMessage, color) => {
    setToast({ show: true, toastMessage: toastMessage, color: color })
    setTimeout(() => setToast({ show: false, toastMessage: ' ', color: '' }), 5000)
  }


  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const firstName = data.get('firstName');
    const email = data.get('email');
    if (!firstName || !email) {
      return showToast('All fields are required.', '#de350b')
    }
    fetch(`${config.baseUrl}/signup`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        firstName,
        email
      })
    })
      .then(resp => resp.json())
      .then(data => {
        if (data === 'success') {
          showToast('Subscribed', '#ddffcc');
          return setTimeout(() => history.push('/'), 5000)
        } else {
          return showToast('Subscription Failed, all fields are requied', '#de350b');
        }
      })
      .catch(error => console.log(error))
  }

  return (
    <div className="scroll-slides about-us d-flex">
      <Fade right delay={500} duration={1500}>
        <div className="hero-text hero-rear">
          <h1 className="hero-title title-rear">{title}</h1>
        </div>
      </Fade>
      <div className="hero-section">
        <Fade right delay={500} duration={1500}>
          <div className="hero-text hero-face">
            <h1 className="hero-title title-face">{title}</h1>
          </div>
        </Fade>
        <div className="au-container d-flex">
          <Slide right duration={1500}>
            <div className="au-image bg-image" style={{ backgroundImage: `url(${image})` }}></div>
          </Slide>
          <Fade cascade delay={1000} duration={1500}>
            <div className="au-contents">
              <h3>
                {ReactHtmlParser(content)}
              </h3>
              <div className="btn-container">
                <Link to="/about-detail" className="btn btn-more">Read More</Link>
              </div>
              <div className="newsletter">
                <h4>Subscribe to our Newsletter</h4>
                <form onSubmit={handleSubmit} className="" method="POST">
                  <div className="form-element form-element-name">
                    <label>Name <span></span></label>
                    <input name="firstName" type="text" placeholder="John Doe" required
                    />
                  </div>
                  <div className="form-element form-element-email">
                    <label>Email <span></span></label>
                    <input email="email" type="text" name="email" placeholder="mail@johndoe.com"
                      pattern="[^ @]*@[^ @]*"
                      required
                    />
                  </div>
                  <div className="form-element form-element-submit">
                    <label>Submit</label>
                    <input type="submit" value="Submit" placeholder="Submit" />
                  </div>
                </form>
                <ToastMessage show={show} toastMessage={toastMessage} color={color} className="toast-message" />
              </div>
            </div>
          </Fade>
        </div>
        <Flash delay={1500} duration={1000}>
          <div className="arrow-nav">
            <Link to="/projects"></Link>
          </div>
        </Flash>
      </div>
      <div className="hero-counter">
        0{position}<span>/04</span>
      </div>
    </div>
  );
}

export default withRouter(AboutUs);
