import React from 'react';


import '../../components/discover/discover';
import Discover from '../../components/discover/discover';
import Footer from '../../components/footer/footer';
import './discover.styles.scss';

const DiscoverPage = () => {
    return (
        <div className="discover-page">
            <Discover />
            <Footer />
        </div>
    );
}

export default DiscoverPage;