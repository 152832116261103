import React from 'react';
import Give from '../../components/give/give';
import Footer from '../../components/footer/footer';
const GivePage = () => {
    return (
        <>
            <Give/>
            <Footer/>
        </>
    );
}

export default GivePage;