import React from 'react';
import PAGE_CONTENTS from '../../data';

import './give.styles.scss';
import { calculateRaisedPercentage, convertObjectToArray } from "../utils/utils";

const Give = () => {
    const { projectsDetails } = PAGE_CONTENTS.projects
    const projectsArray = convertObjectToArray(projectsDetails);

    return projectsArray && (
            <div className="wrapper-md">
                <h2>Support Our Projects</h2>
                <div className="section-wrapper">
                   {
                      projectsArray.map((project, idx) => {
                        const thermometer = calculateRaisedPercentage(
                            project.raised,
                            project.budget 
                        );
                        return  <div key={idx} className="section">
                        <div className="background-image"  style={{ backgroundImage: `url(${project.image})`}}/>
                        <div className="amount-loader">
                            <h4>{project.title}</h4>
                            {/* <div className="loader-text">
                                { project.isMonthly ? `Monthly ${project.raised} of ${project.budget}` : `${project.raised} of ${project.budget}` }
                            </div>
                            <div className="loader-container">
                                <span className="loader" style={{ width: `${thermometer}%` }}/>
                                <span className="loader-text-inner">{thermometer}%</span>
                            </div> */}
                            <div className="loader-text-link">
                                <a className="custom-dbox-popup" href={project.giveUrl}>
                                Give to this project
                                </a>
                                <span>&#10140;</span>
                              {/* <button> <a className="custom-dbox-popup dbox-donation-button" href="https://donorbox.org/jeewit-bachan-online-rn"> Give to this project <span>&#10140;</span></a></button> */}
                            </div>
                        </div>
                    </div>
                       })
                   }
                </div>
            </div>
    );
}

export default Give;