
import React, {useEffect} from 'react';
// import Fade from 'react-reveal/Fade';
import { useHistory } from "react-router-dom";


import Projects from '../../components/projects/projects';
import {routes, debounce } from '../../scroll';


const ProjectPage = () => {

    const history = useHistory();

    var bufferFn = debounce(changeSlide,400);

    useEffect(()=>{
        window.addEventListener('wheel',bufferFn);
        document.body.classList.add('mac-scroll');

        return () => {
            window.removeEventListener('wheel', bufferFn);
            document.body.classList.remove('mac-scroll');

        
        };
      },[]);

      function changeSlide(e) {
        let delta = e.deltaY;
        if (delta >= 0) {
            //next
            history.replace(routes[3].route);
        } else if (delta < 0) {
            //prev            
            history.replace(routes[1].route);
        }
    }

    return (
        <>
            {/* <Fade right duration={300}> */}
                <Projects/>
            {/* </Fade> */}
        </>
    );
}

export default ProjectPage;