import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PAGE_CONTENTS from '../../data';
import './projects.styles.scss';

import Fade from 'react-reveal/Fade';
import Flash from 'react-reveal/Flash';
import Slide from 'react-reveal/Slide';


const Projects = () => {


    const [image, setImage] = useState(PAGE_CONTENTS.projects.jeewitBachanOnline.image);
    const { projects } = PAGE_CONTENTS;
    const { jeewitBachanOnline, buildingProject, publishing, translation, healthOutreach } = PAGE_CONTENTS.projects;

    const convertProjectsDataToMap = (projects) => {
        return Object.keys(projects).map(project => projects[project]);
    }

    const handleMouseOver = (event) => {
        const { name } = event.target;
        const projectArray = convertProjectsDataToMap(projects);
        const getActiveElement = document.querySelector('.active');
        getActiveElement && getActiveElement.setAttribute('class', '');
        projectArray.map(project => {
            return project.title === name && setImage(project.image)
        })
    }

    return (
        <div className="explore-projects">
            <section id="projects" className="projects no-padding">
                <div className="wrapper">
                        <div className="project-container container-mt">
                            <Fade right duration={1200}>
                            <div className="col-two project-titles">
                                <ul className="project-list">
                                <Fade delay={500} duration={1000}>
                                    <li className="jeewit active">
                                        <Link to={`${jeewitBachanOnline.linkUrl}`} name={jeewitBachanOnline.title} onMouseOver={(event) => handleMouseOver(event)}>{jeewitBachanOnline.title}</Link>
                                        <div className="pl-image bg-image" style={{ backgroundImage: `url(${jeewitBachanOnline.image})` }}></div>
                                    </li>
                                </Fade>

                                {/* <Fade down delay={700} duration={1000}>
                                    <li className="building">
                                        <Link to={`${buildingProject.linkUrl}`} name={buildingProject.title} onMouseOver={(event) => handleMouseOver(event)}>{buildingProject.title}</Link>
                                        <div className="pl-image bg-image" style={{ backgroundImage: `url(${buildingProject.image})` }}></div>
                                    </li>
                                </Fade> */}
                                <Fade down delay={800} duration={1000}>
                                    <li className="publishing">
                                        <Link to={`${publishing.linkUrl}`} name={publishing.title} onMouseOver={(event) => handleMouseOver(event)}>{publishing.title}</Link>
                                        <div className="pl-image bg-image" style={{ backgroundImage: `url(${publishing.image})` }}></div>
                                    </li>
                                </Fade>
                                <Fade down delay={900} duration={1000}>
                                    <li className="translation">
                                        <Link to={`${translation.linkUrl}`} name={translation.title} onMouseOver={(event) => handleMouseOver(event)}>{translation.title}</Link>
                                        <div className="pl-image bg-image" style={{ backgroundImage: `url(${translation.image})` }}></div>
                                    </li>
                                </Fade>
                                <Fade down delay={1000} duration={1000}>
                                    <li className="health">
                                        <Link to={`${healthOutreach.linkUrl}`} name={healthOutreach.title} onMouseOver={(event) => handleMouseOver(event)}>Health Outreach</Link>
                                        <div className="pl-image bg-image" style={{ backgroundImage: `url(${healthOutreach.image})` }}></div>
                                    </li>
                                </Fade>
                                </ul>
                            </div>
                            </Fade>
                            <Slide right  duration={1500}>
                                <div className="col-two project-image bg-image" style={{ backgroundImage: `url(${image})` }}></div>
                            </Slide>
                        </div>
                </div>
            </section>
            <Flash delay={2000} duration={1000}>
                <div className="arrow-nav">
                    <Link to="/connect"></Link>
                </div>
            </Flash>
        </div>
    );
}

export default withRouter(Projects);