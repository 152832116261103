import React from 'react';
import FeaturedProjects from '../../components/featured-projects/featured-projects';

import Fade from 'react-reveal/Fade';
const FeaturedProjectsPage = () => {
    return(
        <div className="about-page">
            <div className="vh-section">
                <div className="scroll-container">
                    <div className="scroll-wrapper">
                        <Fade right>
                            <FeaturedProjects/>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FeaturedProjectsPage;